<template>
	<div>
		<div
			v-if="Object.keys(fullDescription).length"
			class="contest landing-simple-block"
		>
			<div class="contest-cover">
				<div class="contest-cover-pic">
					<div
						class="image-cover-wrap"
						:style="fullDescription.image | bgrDynamicImageCover"
					/>
				</div>
				<div class="contest-cover-content">
					<div class="content">
						<div class="grid">
							<div class="box">
								<Breadcrumb :page-name="fullDescription.title" />
								<h3 class="page-title">
									{{ fullDescription.title }}
								</h3>
								<div class="text">
									<p>{{ fullDescription.subtitle }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				v-for="(about, index) in fullDescription.description"
				:key="index"
			>
				<section
					v-if="about.textunit"
					class="section-simple wow"
				>
					<div class="content">
						<h3 class="block-title">
							{{ about.title_unit }}
						</h3>
						<div class="text md dark">
							<p
								:style="{textAlign: about.align}"
								v-html="about.text_unit"
							/>
						</div>
					</div>
				</section>

				<section
					v-if="about.photounit"
					class="section-simple wow"
				>
					<div class="content">
						<a
							v-if="about.photo_url"
							:href="about.photo_url"
							target="_blank"
						>
							<img
								:src="about.photo"
								alt="Картинка мероприятия"
								class="landing-simple-pic"
							>
						</a>
						<img
							v-else
							:src="about.photo"
							alt="Картинка мероприятия"
							class="landing-simple-pic"
						>
					</div>
				</section>

				<section
					v-if="about.buttonunit && about.text_button !== 'Пройти тест'"
					class="section-simple wow"
				>
					<div
						class="content"
						:style="{textAlign: about.align}"
					>
						<a
							v-if="about.target_blank"
							class="button"
							:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
							@click="goToLink(about.url_button, about.text_button)"
						>
							{{ about.text_button }}
						</a>
						<a
							v-else
							:href="about.url_button"
							class="button"
							:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
						>
							{{ about.text_button }}
						</a>
					</div>
				</section>
				<section
					v-if="about.buttonunit && about.text_button === 'Пройти тест'"
					class="section-simple wow"
				>
					<div
						class="content"
						:style="{textAlign: about.align}"
					>
						<a
							v-if="isLogged"
							class="button"
							:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
							@click="goToTest(about.url_button)"
						>
							{{ about.text_button }}
						</a>
						<button
							v-else
							class="button"
							:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
							@click.prevent="modalOpen('regIn', 'test')"
						>
							Регистрация
						</button>
					</div>
				</section>

				<section
					v-if="about.informerunit && about.info.length"
					class="section-simple light wow"
				>
					<div class="content">
						<div class="company-page-feature-block">
							<div
								v-for="(informer, ind) in about.info"
								:key="ind"
								class="company-page-feature-item"
							>
								<div
									v-if="informer.photo"
									class="company-page-feature-icon"
								>
									<img
										:src="informer.photo"
										alt="Информер мероприятия"
									>
								</div>
								<div class="text">
									<p>{{ informer.text }}</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					v-if="about.sliderunit && about.photos"
					class="section wow"
				>
					<div class="content">
						<div class="content">
							<VueSlickCarousel
								class="company-page-slider"
								v-bind="carouselEventSettings"
							>
								<div
									v-for="(image, ind) in about.photos"
									:key="ind"
								>
									<div class="company-page-slide">
										<img
											:src="image"
											alt="Слайд мероприятия"
										>
									</div>
								</div>
							</VueSlickCarousel>
						</div>
					</div>
				</section>

				<div
					v-if="about.form_active && !fullDescription.is_current_user_already_registered"
					id="registration"
					style="scroll-margin-top: 250px;"
				>
					<section
						v-if="isLogged"
						class="section-simple wow"
					>
						<div class="content">
							<div class="center">
								<button
									v-if="!isSended"
									class="button orange lg"
									@click.prevent="registration"
								>
									{{ fullDescription.register_button_text }}
								</button>
								<div
									v-else
									class="button orange create-resume-save lg button-preloader"
								>
									Заявка отправляется
									<div class="button-preloader-icons">
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
										<div class="button-preloader-icon" />
									</div>
								</div>
							</div>
						</div>
					</section>

					<section
						v-else
						class="section-simple wow"
					>
						<div class="content">
							<div class="form-block">
								<form
									class="form"
									novalidate
									@submit.prevent="registration"
								>
									<div class="field-wrap">
										<label class="field-label">
											<label class="field-label">
												<input
													v-model.trim="$v.EL.first_name.$model"
													type="text"
													class="field"
													placeholder="Имя"
													style="text-transform: capitalize"
												>
												<div class="label">Имя</div>
												<span
													v-if="$v.EL.first_name.$error"
													class="help-block form-error"
												>
													{{
														!$v.EL.first_name.required ? 'Это обязательное поле' : $v.EL.first_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
													}}
												</span>
											</label>
										</label>
									</div>
									<div class="field-wrap">
										<label class="field-label">
											<input
												v-model.trim="$v.EL.email.$model"
												type="email"
												class="field"
												placeholder="Email"
											>
											<div class="label">Email</div>
											<span
												v-if="$v.EL.email.$error"
												class="help-block form-error"
											>
												{{ !$v.EL.email.required ? 'Это обязательное поле' : !$v.EL.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
											</span>
										</label>
									</div>
									<c-select
										class="field-wrap"
										:options="roleOptions"
										:name="'Роль'"
										:default-data="EL.user_role"
										:is-error="$v.EL.user_role && !$v.EL.user_role.required"
										@selected="selectRole"
									/>
									<div class="field-wrap">
										<div
											v-if="!isPromoCode"
											class="signup-code"
										>
											<span
												class="signup-code__link"
												@click="isPromoCode = !isPromoCode"
											>У вас есть промокод?</span>
										</div>
										<BaseInput
											v-else
											v-model="EL.referral_code"
											label="Введите промокод"
											:style="'height: 42px;'"
											:type="'text'"
										/>
									</div>
									<div class="field-wrap sm">
										<label class="checkbox-filter">
											<input
												v-model="$v.isPersonal.$model"
												type="checkbox"
												@change="$v.isPersonal.$touch()"
											>
											<span class="info">Соглашаюсь с условиями обработки <a
												href="/privacy-policy"
												target="_blank"
											>персональных данных</a></span>
											<div
												v-if="$v.isPersonal.$error"
												class="help-block form-error"
											>Необходимо
												подтверждение
											</div>
										</label>
									</div>
									<div class="field-wrap">
										<label class="checkbox-filter">
											<input
												v-model="$v.isAgree.$model"
												type="checkbox"
												@change="$v.isAgree.$touch()"
											>
											<span class="info">Соглашаюсь с условиями <a
												href="/agreement"
												target="_blank"
											>пользовательского соглашения</a></span>
											<div
												v-if="$v.isAgree.$error"
												class="help-block form-error"
											>Необходимо подтверждение</div>
										</label>
									</div>
									<button
										v-if="!isSended"
										class="button orange lg"
									>
										{{ fullDescription.register_button_text }}
									</button>
									<div
										v-else
										class="button orange create-resume-save lg button-preloader"
									>
										Заявка отправляется
										<div class="button-preloader-icons">
											<div class="button-preloader-icon" />
											<div class="button-preloader-icon" />
											<div class="button-preloader-icon" />
										</div>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
		<div v-else-if="isError">
			<Error />
		</div>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';
import {email, required, sameAs} from 'vuelidate/lib/validators';
import {alphaSpace} from '@/assets/scripts/custom_validators';
import {mapActions, mapGetters} from 'vuex';
import Cnt from '@/api/contest';
import Error from '@/pages/extra/Error'

export default {
    name: 'Landing',
    components: {
        Breadcrumb,
        Error,
        BaseInput
    },
    data: () => ({
        isSended: false,
        isPromoCode: false,
        fullDescription: {},
        isError: false,
        isLoading: false,
        sendAnalitics: false,
        carouselEventSettings: {
            arrows: true,
            dots: true,
            infinite: false,
            'slides-to-show': 1,
            'slides-to-scroll': 1,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        EL: {
            first_name: '',
            email: '',
            user_role: '',
            external_service: {
                slug: ''
            },
            referral_code: ''
        },
        isPersonal: false,
        isAgree: false,
    }),
    validations: {
        EL: {
            first_name: {
                required,
                alphaSpace
            },
            email: {
                required,
                email
            },
            user_role: {
                required
            }
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
        }),
        ...mapGetters('cnst', {
            eduOptions: 'getEducation',
						roles: 'getRoles'
        }),
        ...mapGetters('smpl', {
            eventSample: 'getEventSample'
        }),
				roleOptions() {
					return this.roles.map(x => x.value);
				}
    },
    created() {
        this.getEventData()
    },
    methods: {
        ...mapActions('smpl', [
            'getEventSample',
        ]),
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace',
            loginEvent: 'loginEvent'
        }),
        selectRole(value) {
            this.EL.user_role =this.roles.find(rol => rol.value === value).slug;
        },
        getEventData() {
            this.getEventSample(this.$route.params.slug)
                .then( response => {
                    this.fullDescription = response
                    this.EL.external_service.slug = this.$route.params.slug
                    return this.isLoading = false
                })
                .catch( () => {
                    this.isError = true
                    // this.$router.push(`/event/${this.$route.params.slug}/*`)
                })
        },
        registration() {
            if (!this.isLogged) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    console.log('Registration form is error')
                } else if (!this.isSended) {
                    if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/event/konkurs-merch') {
                        this.$metrika.reachGoal('designmerch')
                    }
                    if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru')) {
                        this.$metrika.reachGoal('projectregistrtion')
                    }
                    this.isSended = true
                    const sendData = {
                        first_name: this.EL.first_name,
                        email: this.EL.email,
                        external_service: {slug: this.EL.external_service.slug},
                        user_role: this.EL.user_role
                    }
                    if (this.EL.referral_code) sendData.referral_code = this.EL.referral_code
                    this.loginEvent({ data: {...sendData}, slug: this.$route.params.slug })
                        .then(() => {
                            this.modalOpen('eventSuccess')
                            this.$v.EL.$reset()
                            this.$v.isPersonal.$reset()
                            this.$v.isAgree.$reset()
                            this.EL = {
                                first_name: '',
                                email: '',
                                user_role:''
                            }
                            this.isPersonal = false
                            this.isAgree = false
                        })
                        .catch(error => this.modalOpen('eventFail', error))
                        .finally(() => this.isSended = false)
                }
            } else {
                this.EL.first_name = this.user.first_name
                this.EL.email = this.user.email
                this.isSended = true
                if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/event/konkurs-merch') {
                    this.$metrika.reachGoal('designmerch')
                }
                const sendData = {
                    first_name: this.user.first_name,
                    email: this.user.email,
                    external_service: {slug: this.EL.external_service.slug},
                    user_role: this.user.user_role
                }
                if (this.EL.referral_code) sendData.referral_code = this.EL.referral_code
                Cnt.registerToEvent({ data: {...sendData}, slug: this.$route.params.slug })
                    .then(() => {
                        this.modalOpen('eventSuccess')
                        this.$v.EL.$reset()
                        this.$v.isPersonal.$reset()
                        this.$v.isAgree.$reset()
                        this.EL = {
                            first_name: '',
                            email: '',
                            role: '',
                        }
                        this.isPersonal = false
                        this.isAgree = false
                    })
                    .catch(error => this.modalOpen('eventFail', error.response.data.error_text))
                    .finally(() => this.isSended = false)
            }
        },
        goToTest(url) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/event/konkurs-merch') {
                this.$metrika.reachGoal('softskillstest1')
            }
            if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/event/test_readytowork') {
                this.$metrika.reachGoal('workreadytest2')
            }
            window.location.href = url
        },
        goToLink(url, text) {
            if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && text === 'Подать заявку') {
                this.$metrika.reachGoal('cosmosint')
            }
            window.open(url, '_blank')
        }
    }
}
</script>

<style lang="less">
.company-page-slide img {
    height: 547px !important;
}
@import '../../assets/styles/pages/landing-simple-template';

.company-page-feature-block {
    align-items: flex-start;
}
</style>

<style scoped>
h1, h2, h3, h4, h5, h6, p {
    word-break: break-word
}
.wow {
    opacity: 1 !important
}
</style>
